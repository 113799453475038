input, textarea{
  background-color: rgba(255,255,255,0);
  border:none;
  /* border-bottom: solid 1px #3838bf; */
  border: solid 1px #9A98D5;
  border-radius: 4px;
  padding:8px
}
input.regular{
  height: 44px;
}
input.big{
  height: 56px;
}
input.regular:hover{
  background-color: rgba(255,255,255,0.2);
}
input[type='radio']{
  margin:0;
  margin-right: 8px;
}
input[type='checkbox']{
  margin:0;
  margin-right: 8px;
}
textarea.big{
  padding: 12px 8px;
  min-height: 112px;
  line-height: 25px;
}

textarea.comment{
  padding:16px;
  height: 100px;
  border-radius: 22px;
  border: solid 1px #9b9bdf;
  background: none !important;
}

/*Date Picker Container of D-day*/
.MuiTextField-root{
  height: 44px;
  border-radius: 22px;
  border: solid 1px #9b9bdf !important;
  padding-left: 16px !important;
  width: 100%;
  display: flex !important;
  flex-direction: row !important;
  align-items: center;
  justify-content: flex-start;
  margin-top: 0px !important;
}
.MuiInput-underline:before{
  border: none !important;
}
.MuiInput-formControl{
  width: 100%;
}
::placeholder{
  color: #93908b !important;
  font-family: sopoqa_han_sans_regular;
}