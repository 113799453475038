* {
  box-sizing: border-box;
}

.col1 {
  width: 100%;
}
.col1_m {
  width: 100%;
}
.col2 {
  width: 50%;
}
.col1_m > .col2 {
  width: calc(50% - 8px);
}
.col3 {
  width: calc(100% / 3);
}
.col4 {
  width: 25%;
}
.col4_2 {
  width: 25%;
}
.col5 {
  width: 20%;
}
.col6 {
  width: calc(100% / 6);
}

.col_p66 {
  width: 66%;
}
.col_p60 {
  width: 60%;
}
.col_p40 {
  width: 40%;
}
.col_p33 {
  width: 33%;
}

.row1 {
  height: 100%;
}
.row2 {
  height: 50%;
}
.row_regular {
  height: 44px;
}
.margin_r_0 {
  margin-right: 0px !important;
}
.col_m > .col2 {
  width: calc((100% - 40px) / 2);
  margin-right: 40px;
}
.col_m > .col3 {
  width: calc((100% - 80px) / 3);
  margin-right: 40px;
}
.col_m > .col4 {
  width: calc((100% - 120px) / 4);
  margin-right: 40px;
}
.col_m > .col5 {
  width: calc((100% - 160px) / 5);
  margin-right: 40px;
}
.col_m > .col6 {
  width: calc((100% - 200px) / 6);
  margin-right: 40px;
}

.col3_m {
  width: calc((100% - 32px) / 3);
}
.col2_m {
  width: calc((100% - 16px) / 2);
}

.col2_1 {
  width: calc(100% / 2);
}
.col_m > .col2_1 {
  width: calc((100% - 40px) / 2);
  margin-right: 40px;
}
.col_m > .col3_1 {
  width: calc((100% - 80px) / 3);
  margin-right: 40px;
}
.col_m > .col4_1 {
  width: calc((100% - 120px) / 4);
  margin-right: 40px;
}
.col_m > .col4_2 {
  width: calc((100% - 120px) / 4);
  margin-right: 40px;
}
.col_m > .col5_2 {
  width: calc((100% - 160px) / 5);
  margin-right: 40px;
}
.col_m > .col6_2 {
  width: calc((100% - 200px) / 6);
  margin-right: 40px;
}

@media screen and (max-width: 480px) {
  .col4_2 {
    width: 50%;
  }
  .col_m > .col2_1 {
    width: calc((100% - 0px) / 1);
    margin-left: 0px;
    margin-right: 0px;
  }
  .col_m > .col3_1 {
    width: calc((100% - 0px) / 1);
    margin-left: 0px;
    margin-right: 0px;
  }
  .col_m > .col4_1 {
    width: calc((100% - 0px) / 1);
    margin-left: 0px;
    margin-right: 0px;
  }
  .col_m > .col4_2 {
    width: calc((100% - 40px) / 2);
    margin-right: 40px;
  }
  .col_m > .col5_2 {
    width: calc((100% - 40px) / 2);
    margin-right: 40px;
  }
  .col_m > .col6_2 {
    width: calc((100% - 40px) / 2);
    margin-right: 40px;
  }
  .col1_mobile {
    width: 100% !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
}
.col_m > .col_p66 {
  width: calc(66% - 40px);
  margin-right: 40px;
}
.col_m > .col_p33 {
  width: calc(33% - 40px);
  margin-right: 40px;
}
.col_m > .col_p40 {
  width: calc(40% - 20px);
  margin-right: 40px;
}
.col_m > .col_p60 {
  width: calc(60% - 20px);
  margin-right: 40px;
}

.margin_1x {
  margin: 16px;
}
.margin_2x {
  margin: 32px;
}
.margin_3x {
  margin: 48px;
}
.margin_4x {
  margin: 64px;
}
.margin_4px{
  margin: 4px;
}
.margin_avatar {
  margin-right: 4px;
  margin-bottom: 4px;
}
.margin_tiny {
  margin: 8px;
}

.margin_t_1x {
  margin-top: 16px;
}
.margin_t_minus_1x {
  margin-top: -16px;
}
.margin_ver_1x {
  margin-top: 16px;
  margin-bottom: 16px;
}
.margin_ver_2x {
  margin-top: 32px;
  margin-bottom: 32px;
}
.margin_t_2x {
  margin-top: 32px !important;
}
.margin_t_3x {
  margin-top: 48px;
}
.margin_t_4x {
  margin-top: 64px;
}
.margin_r_tiny {
  margin-right: 8px;
}
.margin_r_1x {
  margin-right: 16px;
}
.margin_r_2x {
  margin-right: 32px;
}
.margin_r_3x {
  margin-right: 48px;
}
.margin_r_4x {
  margin-right: 64px;
}

.margin_b_1x {
  margin-bottom: 16px;
}
.margin_b_tiny {
  margin-bottom: 8px;
}
.margin_b_2x {
  margin-bottom: 32px;
}
@media screen and (max-width: 480px) {
  .margin_b_2x_mobile {
    margin-bottom: 32px;
  }
}
.margin_b_3x {
  margin-bottom: 48px;
}
.margin_b_4x {
  margin-bottom: 64px;
}
.margin_b_5x {
  margin-bottom: 80px;
}
.margin_b_6x {
  margin-bottom: 96px;
}
.margin_b_7x {
  margin-bottom: 112px;
}
.margin_b_8x {
  margin-bottom: 128px;
}
.padding_tiny {
  padding: 8px;
}
.padding_1x {
  padding: 16px;
}
.padding_2x {
  padding: 32px;
}
.padding_3x {
  padding: 48px;
}
.padding_4x {
  padding: 64px;
}

.padding_t_1x {
  padding-top: 16px;
}
.padding_t_2x {
  padding-top: 32px;
}
.padding_t_3x {
  padding-top: 48px;
}
.padding_t_4x {
  padding-top: 64px;
}

.padding_r_1x {
  padding-right: 16px;
}
.padding_r_2x {
  padding-right: 32px;
}
.padding_r_3x {
  padding-right: 48px;
}
.padding_r_4x {
  padding-right: 64px;
}

.padding_l_1x {
  padding-left: 16px;
}
.padding_l_2x {
  padding-left: 32px;
}
.padding_l_3x {
  padding-left: 48px;
}
.padding_l_4x {
  padding-left: 64px;
}

.padding_b_1x {
  padding-bottom: 16px;
}
.padding_b_2x {
  padding-bottom: 32px;
}
.padding_ver_1x {
  padding-top: 16px;
  padding-bottom: 16px;
}
.padding_ver_2x {
  padding-top: 32px;
  padding-bottom: 32px;
}
.padding_hor_1x {
  padding-left: 16px;
  padding-right: 16px;
}
.padding_hor_2x {
  padding-left: 32px !important;
  padding-right: 32px !important;
}
.padding_b_3x {
  padding-bottom: 48px;
}
.padding_b_4x {
  padding-bottom: 64px;
}
.padding_b_20px {
  padding-bottom: 20px;
}
