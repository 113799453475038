::-webkit-scrollbar {
  /* width: 10px; */
}
@media screen and (max-width: 480px) {
  ::-webkit-scrollbar {
    /* width: 0px; */
  }
}

/* Track */
::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px grey;  */
  /* border-radius: 10px; */
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #CDCDEF; 
  /* border-radius: 10px; */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(0,0,0,0); 
}

a {
  text-decoration: none;
  color: inherit;
}
button {
  background-color: initial;
  border: initial;
  outline: none;
  cursor: pointer;
  padding: initial;
}
.gnb {
  /* margin-top: 48px; */
  height: 80px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: solid 2px #443cba;
}
.menu_gnb {
  position: relative;
}
.menu_gnb:before {
  opacity: 0.64;
}
.menu_gnb#left:before {
  position: absolute;
  content: '';
  top: 16px;
  right: 0px;
  width: 1px;
  height: calc(100% - 32px);
  background-color: #443cba;
}
/* Do not use empty rulesets
.menu_gnb#center:before{
    
}
*/
.menu_gnb#right:before {
  position: absolute;
  content: '';
  top: 16px;
  left: 0px;
  width: 1px;
  height: calc(100% - 32px);
  background-color: #443cba;
}
.search {
  margin-right: 24px;
}
.signin {
  margin-right: 16px;
}
.signup {
  padding-right: 3px;
}
@media screen and (max-width: 480px) {
  .gnb {
    margin-top: 0px;
    height: 52px;
    padding: 0 20px;
    border-top: none;
    border-bottom: solid 3px #443cba;
  }
  .bottom_navigation {
    position: fixed !important;
    left: 0 !important;
    -webkit-backface-visibility: hidden;
    bottom: 0 !important;
    height: 61px;
    border-top: solid 1px #443cba;
    z-index: 9;
    width: 100%;
  }
  .bottom_navigation > .menu_gnb {
    width: 20%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.btt_regular {
  padding: 0 24px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  border-radius: 16px;
  font-family: sopoqa_han_sans_bold;
}
.btt_big {
  padding: 0 48px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  border-radius: 16px;
  font-family: sopoqa_han_sans_bold;
}
.btt_underline {
  min-width: 108px;
  color: #443cba;
  font-size: 16px;
  display: flex;
  align-items: center;
  height: 32px;
  font-family: sopoqa_han_sans_bold;
  border-bottom: solid 2px #443cba;
}
.btt_review {
  height: 100%;
  border-radius: 2px 2px 0 0;
}
.btt_txt {
  position: relative;
  color: #443cba;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 26px;
  font-family: sopoqa_han_sans_bold;
}
.btt_txt_grey {
  padding: 16px;
  position: relative;
  color: #78786e;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 26px;
  font-family: sopoqa_han_sans_bold;
}
.btt_circle_big {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  font-size: 12px;
  font-family: sopoqa_han_sans_bold;
}
.btt_write {
  width: 134px;
  height: 48px;
  font-family: sopoqa_han_sans_bold;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(../img/shape/bg_write_outline.svg);
  background-size: cover;
}
@media screen and (max-width: 480px) {
  .btt_write {
    width: 113px;
    height: 40px;
    background-image: url(../img/shape/bg_write_outline_mobile.svg);
    background-size: cover;
  }
}
.btt_write.small {
  width: 116px;
  height: 41px;
}
.btt_write:hover {
  background-image: url(../img/shape/bg_write_outline_rollover.svg);
}
.btt_write.loged {
  /* background-image: url(../img/shape/bg_write_filled.svg); */
}

/*CARD*/
/*card regular*/
.card_regular {
  padding-bottom: 16px;
}
.card_regular > h2 {
  height: 48px;
}
.preview {
  word-break: keep-all;
  padding-left: 1px;
}
.preview_reg_card {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  white-space: pre-line;
  height: 84px;
  font-size: 14px;
  line-height: 21px;
}

.card_regular > div > .preview {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  height: 112px;
  white-space: pre-line;
}
.card_regular > div > .preview_s {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  white-space: pre-line;
}
.preview_line2 {
  -webkit-line-clamp: 2 !important;
}
.ad_content {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 9;
}
/**/
.card_middle {
  position: relative;
  height: 321px;
  padding-bottom: 20px;
}
.card_middle > .preview {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  height: 96px;
  white-space: pre-line;
}
.card_middle > div > .preview {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  height: 96px;
  white-space: pre-line;
}
/*card big*/
.card_big > div > .preview {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  /* width: 86px; */
  height: 168px;
  white-space: pre-line;
}
.card_big {
  height: 500px;
  /* border-bottom: solid 0.5px #443CBA; */
  padding-bottom: 24px;
}
@media screen and (max-width: 480px) {
  .card_big {
    height: auto;
    /* border-bottom: solid 0.5px #443CBA; */
    padding-top: 20px;
    padding-bottom: 16px;
  }
}

/*card big*/
.card_full > div > .preview {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  white-space: break-spaces;
}
@media screen and (max-width: 480px) {
  .preview {
    -webkit-line-clamp: 3 !important;
  }
}
.card_full {
  height: 488px;
  border-bottom: solid 0.5px #443cba;
  padding-bottom: 32px;
}
.card_full.ad {
  height: 488px;
  background-color: #443cba;
  padding: 0;
}
.cover_img {
  background-position: center;
  background-size: cover;
  position: relative;
  overflow: hidden;
}
.card_full.ad > .cover_img:before {
  content: '';
  position: absolute;
  opacity: 0.66;
  top: 0;
  left: 0;
  background-color: #443cba;
  width: 100%;
  height: 100%;
  z-index: 9;
}

/*Card Element*/
.tag_area_card {
  height: 46px;
  margin-bottom: 16px;
  overflow: hidden;
}
@media screen and (max-width: 480px) {
  .tag_area_card {
    height: 46px;
  }
}

.cover_img:before {
  content: '';
  position: absolute;
  opacity: 0.66;
  top: 0;
  left: 0;
  background-color: #443cba;
  width: 100%;
  height: 100%;
  z-index: 9;
}
.alarm {
  margin-left: -8px;
  margin-right: 24px;
  width: 48px;
}
@media screen and (max-width: 480px) {
  .alarm {
    margin-left: -8px;
    width: 48px;
    margin-right: 0px;
  }
}
.avatar_h {
  width: 52px;
  height: 52px;
  border-radius: 50%;
  background-color: #443cba;
  color: #ffffff;
  font-size: 12px;
  font-family: sopoqa_han_sans_regular;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.avatar_b {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background-color: #443cba;
  color: #ffffff;
font-size: 12px;
  font-family: sopoqa_han_sans_regular;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.avatar_m {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: #443cba;
  color: #ffffff;
  font-size: 12px;
  font-family: sopoqa_han_sans_regular;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.avatar_s {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: #443cba;
  color: #ffffff;
  font-size: 12px;
  font-family: sopoqa_han_sans_regular;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

/*card competition*/
.card_competition_full {
  /* border-bottom: solid 0.5px #443CBA; */
  height: 321px;
  padding-bottom: 20px;
}
/*Competition Info*/
.card_info_competition {
  height: 250px;
}
@media screen and (max-width: 480px) {
  .card_info_competition {
    height: 128px;
  }
}

/*TOOLTIP*/
.tooltip_filled_dark {
  font-family: sopoqa_han_sans_regular;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #443cba;
  color: white;
  font-size: 12px;
  padding: 0 4px;
  height: 24px;
  border-radius: 12px;
}
.tooltip_filled_light {
  font-family: sopoqa_han_sans_regular;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  color: #443cba;
  font-size: 12px;
  padding: 0 4px;
  height: 24px;
  border-radius: 12px;
}
.tooltip_outlined_light {
  font-family: sopoqa_han_sans_regular;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px #fff;
  color: #fff;
  font-size: 11px;
  line-height: 13px;
  padding: 0 6px;
  height: 20px;
  border-radius: 12px;
}
.tooltip_outlined_dark {
  cursor: pointer;
  font-family: sopoqa_han_sans_regular;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px #cdcdef;
  color: #443cba;
  font-size: 11px;
  line-height: 13px;
  padding: 0 6px;
  height: 24px;
  border-radius: 12px;
}

/*Thumbmail Image*/
.img_thumb {
  display: block;
  min-width: 100%;
  max-height: 100%;
  filter: grayscale(86) brightness(1.54);
}

/*Section Top*/
.section_top {
  position: relative;
  border-bottom: solid 2px #443cba;
  padding-bottom: 16px;
  margin-top: 16px;
  margin-bottom: 32px;
}
.section_top > .img_right {
  position: absolute;
  bottom: 0;
  right: 0;
}
.section_top > .img_left {
  transform: scaleX(-1);
  position: absolute;
  bottom: 0;
  left: 0;
}
@media screen and (max-width: 480px) {
  .section_top {
    margin-bottom: 8px;
  }
}

/*Tab*/
/* .tab_basic{
    position: relative;
    height: 48px;
    width: 124px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-family: sopoqa_han_sans_bold;
    color: #78786E;
    cursor: pointer;
}
.tab_basic.selected{
    color: #443CBA;
    border-bottom: solid 2px #443CBA;
} */
@media screen and (max-width: 480px) {
  .tab_basic {
    width: 108px;
  }
  .tab_container {
    display: -webkit-inline-box !important;
    flex-wrap: nowrap !important;
    overflow-y: scroll;
  }
}

/* 로딩 */
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #3838bf;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis.small {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 32px;
}
.lds-ellipsis.small div {
  position: absolute;
  top: 4.5px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #3838bf;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

/* Transition */
/* Material UI Custom */
.MuiSnackbarContent-root {
  background-color: #443cba !important;
}

.disabled {
  pointer-events: none;
  opacity: 0.64;
}


.hide{
  display: none !important;
}