html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  display: block;
  /* overflow-y: overlay; */
}
html {
  /* height: 100%;
  width: 100%; */
  /* overflow: hidden; */
}

body {
  /* height: 100%; */
  padding: 0;
  /* overflow: auto; */
  margin: 0;
  /* -webkit-overflow-scrolling: touch;
  overflow-x:hidden;
  overflow-y: hidden; */
}
#root{
  overflow: hidden;
  /* overflow-y: auto;
  height: 100%; */
}
.text_center {
  text-align: center;
}
.live_area {
  width: 1200px;
  margin-left: auto;
  margin-right: auto;
}
.col_narrow {
  width: 768px;
}
.margin_center {
  margin-left: auto;
  margin-right: auto;
}
.router_area_top {
  /* margin-top: 80px; */
}
.center_margin {
  margin-left: auto;
  margin-right: auto;
}
@media screen and (max-width: 480px) {
  .router_area_top {
    margin-top: 16px;
  }
  .col_narrow {
    width: 100%;
  }
}

/*DEVICE*/
.only_pc {
  display: inherit;
}
.only_mobile {
  display: none !important;
}

@media screen and (max-width: 480px) {
  .only_pc {
    display: none !important;
  }
  .only_mobile {
    display: inherit !important;
  }
  /*Column*/
  .col_grid_mobile {
    padding: 0 16px;
  }
}
@media screen and (max-width: 480px) {
  .live_area {
    width: 100%;
  }
}

.flex_row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.align_start {
  align-content: flex-start;
}
.flex_col {
  display: flex;
  flex-direction: column;
}
.flex_col_reverse {
  display: flex;
  flex-direction: column-reverse;
}

.flex_row_col {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
@media screen and (max-width: 480px) {
  .flex_row_col {
    display: flex;
    flex-direction: column;
  }
}

.align_start {
  align-items: flex-start;
}
.align_center {
  align-items: center;
}
.align_end {
  align-items: flex-end;
}
.justify_start {
  justify-content: flex-start;
}
.justify_center {
  justify-content: center;
}
.justify_end {
  justify-content: flex-end;
}
.space_between {
  justify-content: space-between;
}
.space_around {
  justify-content: space-around;
}
.flex_center {
  align-items: center;
  justify-content: center;
}

/*divider*/
.dv_left {
  position: relative;
}
.dv_left:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  height: 1px;
  width: calc(100%);
  background-color: #9B9BDF;
}
.dv_left:after {
  content: '';
  position: absolute;
  top: 0px;
  right: -24px;
  height: calc(100% - 24px);
  width: 1px;
  background-color: #9B9BDF;
}

@media screen and (max-width: 480px) {
  .col3_1:after {
    display: none !important;
  }
  .col4_1:after {
    display: none !important;
  }
  .col1_mobile:after {
    display: none !important;
  }
  .no_ver_line_mobile:after {
    display: none !important;
  }
}
.dv_center {
  position: relative;
}
.dv_center:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0px;
  height: 1px;
  width: calc(100%);
  background-color: #9B9BDF;
}
.dv_center:after {
  content: '';
  position: absolute;
  top: 0px;
  right: -24px;
  height: calc(100% - 24px);
  width: 1px;
  background-color: #9B9BDF;
}
.dv_right {
  position: relative;
  margin-right: 0 !important;
}
.dv_right:before {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0px;
  height: 1px;
  width: calc(100%);
  background-color: #9B9BDF;
}
.dv_bottom_bold:before {
  height: 2px;
}

.force_to_static{
  position: static !important;
}

.bottom_24px{
  padding-bottom: 24px;
}