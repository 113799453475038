[contenteditable=true]:empty:before{
  content: attr(placeholder);
  pointer-events: none;
  display: block; /* For Firefox */
  color: #b6aea6;
}
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #b6aea6;
  opacity: 1; /* Firefox */
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #b6aea6;
}
::-ms-input-placeholder { /* Microsoft Edge */
  color: #b6aea6;
}
@font-face {
  font-family: sopoqa_han_sans_light;
  src: url(../font/SpoqaHanSansNeoLight.otf);
}
@font-face {
  font-family: sopoqa_han_sans_regular;
  src: url(../font/SpoqaHanSansNeoRegular.otf);
}
@font-face {
  font-family: sopoqa_han_sans_bold;
  src: url(../font/SpoqaHanSansNeoBold.otf);
}
.regular {
  font-family: sopoqa_han_sans_regular;
}
.bold {
  font-family: sopoqa_han_sans_bold;
}
*{
  /* word-break: keep-all; */
}
p {
  margin: initial;
}
h1 {
  margin: initial;
  font-size: 28px;
  line-height: 37px;
  margin: 0;
  white-space: pre-line;
  word-break: keep-all;
}
h2 {
  margin: initial;
  font-size: 20px;
  line-height: 27px;
  margin: 0;
  white-space: pre-line;
  word-break: keep-all;
}
h3 {
  margin: initial;
  font-size: 15px;
  line-height: 28px;
  margin: 0;
  white-space: pre-line;
  word-break: keep-all;
}
.h3 {
  margin: initial;
  font-size: 15px;
  line-height: 28px;
}
.body {
  font-size: 16px;
  line-height: 24px;
}
.body_s {
  font-size: 14px;
  line-height: 24.8px;
}
.caption {
  font-size: 13px;
  line-height: 20.2px;
}
.caption_s {
  font-size: 11px;
  line-height: 16px;
}
