/* Do not use empty rulesets
.col_main{

}
*/
.col_editor {
  width: 760px;
}
.editor {
  min-height: calc(56px * 5);
  /* background-image: url('../img/shape/bg_underline.svg'); */
  word-break: keep-all;
}
.editor_ins{
  min-height: calc(56px * 5);
  /* background-image: url('../img/shape/bg_underline.svg'); */
  word-break: keep-all;
}
input {
  outline: none;
}
input::placeholder {
  color: #b6aea6;
  font-family: sopoqa_han_sans_regular;
}
input.title {
  font-family: sopoqa_han_sans_bold;
  font-size: 20px;
  line-height: 32px;
  padding:0px !important;
  height: 44px;
  border: none;
  background: none;
  color: #443cba;
  /* border-top: solid 2px #443cba; */
}
@media screen and (max-width: 480px) {
  .col_editor {
    width: 100%;
  }
  .editor {
    min-height: 674px;
    /* background-image: url('../img/shape/bg_underline.svg'); */
  }
  .editor_ins {
    min-height: 674px;
    /* background-image: url('../img/shape/bg_underline.svg'); */
  }
}

/*CUSTOMIZE NATIVE ELEMENT*/
.public-DraftEditor-content {
  font-family: sopoqa_han_sans_light;
}
.graf--p {
  color: #2a2a28;
  font-size: 20px;
  line-height: 40px;
  margin-bottom: 0 !important;
}

/*Placeholder*/
.public-DraftEditorPlaceholder-root {
  background: none;
  background-color: rgba(0, 0, 0, 0) !important;
  padding-top: 12px;
  margin-bottom: 10px !important;
}

/*Image*/

.graf--figure.is-mediaFocused .graf-image,
.graf--figure.is-mediaFocused iframe {
  box-shadow: none !important;
  border: solid 2px #3838bf;
}
/*Hide img border incase of readonly mode*/
div[contenteditable='false'] > div > figure > div > div > img {
  box-shadow: none !important;
}

figcaption {
  display: none !important;
}
.inlineTooltip {
  display: none;
  margin-top: 36px;
  margin-left: 44px;
}
@media screen and (max-width: 480px) {
  #bottom_action_bar {
    display: none;
  }
}

@media screen and (max-width: 480px) {
  #dante-menu {
    /* display: none; */
  }
}

.postContent{
  font-family: sopoqa_han_sans_regular !important;
}



/* Editor Class from Notion Clone */
body {
  /* display: block;
  margin: 0 auto;
  width: 100%;
  max-width: 40rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif; */
}

.Logo {
  margin: 2rem 0;
  width: 100%;
  color: #0f2e53;
  font-size: 1.8rem;
  font-weight: 700;
}

.Intro {
  padding: 0.25rem 1rem 0.25rem 0.5rem;
  margin: 2rem 0;
  border-left: 0.25rem solid #0f2e53;
}

.Code {
  background: #f2f2f2;
  padding: 0.125rem;
  border-radius: 0.125rem;
  font-family: monospace;
}

.Emoji {
  padding-right: 0.5rem;
}

/* EDITABLE BLOCK*/

.Block {
  /* margin: 0.25rem 0; */
  /* padding: 0.5rem; */
  /* background: #fafafa; */
  border-radius: 2px;
  font-family: sopoqa_han_sans_light !important;
  padding: 6px 12px;
  margin-bottom: 10px !important;
  color: #2a2a28;
  font-size: 20px;
  line-height: 40px;
  margin-bottom: 0 !important;
  transition: all 0.3s ease-in-out;
}
.Block:hover {
  /* background: #f8f8f8;
  outline-color: #f5f6fb; */
}
.Block:focus {
  /* background: #f5f6fb; */
  outline: none;
}

/* SELECT MENU */

.SelectMenu {
  position: absolute;
  width: 6.875rem;
  /* height: 9rem; */
  height: auto;
  z-index: 99;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.SelectMenu .Items {
  background: #fafafa;
  box-shadow: rgba(15, 15, 15, 0.05) 0px 0px 0px 1px,
    rgba(15, 15, 15, 0.1) 0px 3px 6px, rgba(15, 15, 15, 0.2) 0px 9px 24px;
  border-radius: 0.5rem;
}
.SelectMenu .Items div[role="button"] {
  font-size: 0.875rem;
  font-weight: 400;
  padding: 0.5rem 1rem;
  border-bottom: 1px solid f5f6fb;
}
.SelectMenu .Items div[role="button"]:hover {
  background: #f5f6fb;
  cursor: pointer;
}
.SelectMenu .Items div[role="button"]:last-of-type {
  border-bottom: none;
}
.SelectMenu .Items div[role="button"]:hover,
.SelectMenu .Items div[role="button"]:focus {
  background: #f5f6fb;
  cursor: pointer;
}
.SelectMenu .Items div[role="button"]:first-of-type:hover,
.SelectMenu .Items div[role="button"]:first-of-type:focus {
  border-top-right-radius: 0.5rem;
  border-top-left-radius: 0.5rem;
}
.SelectMenu .Items div[role="button"]:last-of-type:hover,
.SelectMenu .Items div[role="button"]:last-of-type:focus {
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}
.Selected {
  background: #f5f6fb;
  border-radius: 0.5rem;
}


/* Editor Decoration Classed */
.editor-chapter-title{
  font-family: sopoqa_han_sans_bold !important;
  color: #3838BF;
}

.editor-quotation{
  margin: 16px auto !important;
  padding: 0px 12px;
  color: #3838BF;
  position:relative;
  width: fit-content;
}
.editor-quotation::before{
  position:absolute;
  content:'';
  top:3px;
  left:0;
  width:10px;
  height:8px;
  background-image: url('../img/shape/quote_left.svg');
  background-size: cover;
}
.editor-quotation::after{
  position:absolute;
  content:'';
  top:3px;
  right:0;
  width:10px;
  height:8px;
  background-image: url('../img/shape/quote_right.svg');
  background-size: cover;
}

.editor-highlight{
  position: relative;
  padding: 32px;
  margin: 16px 0px !important;
  text-align: center;
  color: #3838BF;
  /* background-color: #f8f0fa; */
}

.editor-highlight::before{
  position:absolute;
  content:'';
  bottom: 16px;
  left:calc(50% - 2px);
  width:4px;
  height:4px;
  border-radius: 2px;
  background-color:#3838BF;
}
.editor-highlight::after{
  position:absolute;
  content:'';
  top: 16px;
  left:calc(50% - 2px);
  width:4px;
  height:4px;
  border-radius: 2px;
  background-color:#3838BF;
}