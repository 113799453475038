.img_char_male {
  width: 86px;
  height: auto;
  position: absolute;
  right: 0px;
  bottom: 0px;
}
@media screen and (max-width: 480px) {
  .img_char_male {
    width: 56px;
    height: auto;
  }
  .logo {
    width: 96px;
    height: auto;
  }
}
.card_middle > .ic_recommend {
  position: absolute;
  top: 0px;
  right: 0px;
}
