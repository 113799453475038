.bd_bottom{
    border-bottom: solid 1px #443CBA;
}
.bd_bottom_light{
    border-bottom: solid 1px #CDCDEF;
}
.bd_bottom_bold{
    border-bottom: solid 2px #443CBA;
}
.bd_top{
    border-top: solid 1px #443CBA; 
}
.bd_top_bold{
    border-top: solid 2px #443CBA;
}
.bd_top_light{
    border-top: solid 1px #CDCDEF;
}
.bd_right{
    border-right: solid 1px #443CBA;
}


.bd_r_b{
    border-right: solid 1px #443CBA;
}
@media screen and (max-width: 480px) {
    .bd_r_b{
        border-bottom: solid 1px #443CBA;
    }
}