body{
    /* background-color: #FCF6EE; */
}
.primary{
    color: #443CBA;
}
.primary_bg{
    background-color: #443CBA;
}
.primary_bd{
    border:solid 1px #443CBA;
}

.vanila_white_bg{
    background-color: #FCF6EE;
}

.black700{
    color: #2A2A28;
}
.black300{
    color: #78786E;
}

.white{
    color: #ffffff;
}
.white_bg{
    background-color: #ffffff;
}
.white_600bg{
    background-color: #fCfafa;
}
